import React, { useState } from 'react';
import './App.css';

import Header from './Header';
import Footer from './Footer';

import './Video.css'; // Ensure you have this CSS file imported

function App() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setQuestion(e.target.value);
  };

  const onClick = async () => {
    if (!question) {
      alert("Please enter a question.");
      return;
    }

    setLoading(true);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ANzNcKA6dcFXIsofqpyGUuxOuEiVLrEE',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        prompt: `Answer question with explanation ${question}`,
        numResults: 1,
        maxTokens: 2000,
        temperature: 0.7,
        topKReturn: 0,
        topP: 1,
        countPenalty: {
          scale: 0,
          applyToNumbers: false,
          applyToPunctuations: false,
          applyToStopwords: false,
          applyToWhitespaces: false,
          applyToEmojis: false
        },
        frequencyPenalty: {
          scale: 0,
          applyToNumbers: false,
          applyToPunctuations: false,
          applyToStopwords: false,
          applyToWhitespaces: false,
          applyToEmojis: false
        },
        presencePenalty: {
          scale: 0,
          applyToNumbers: false,
          applyToPunctuations: false,
          applyToStopwords: false,
          applyToWhitespaces: false,
          applyToEmojis: false
        },
        stopSequences: []
      })
    };

    try {
      const response = await fetch('https://api.ai21.com/studio/v1/j2-grande-instruct/complete', requestOptions);
      const data = await response.json();
      const text = data.completions[0].data.text;
      setAnswer(text);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      <div className="content-wrap">
        <Header />

        <h1>Homework Solver</h1>
        <p>Enter a question</p>
        <textarea
          onChange={onChange}
          value={question}
          rows={4}
          cols={50}
          placeholder={`Which famous scientist discovered the laws of motion and gravity? \nA) Isaac Newton \nB) Albert Einstein \nC) Galileo Galilei \nD) Stephen Hawking`}
          required
        />

        <button onClick={onClick}>Submit</button>

        <div className="container">
          {loading ? <p className='answer'>StudyPal is thinking ...</p> : <p className='answer'>{answer}</p>}
        </div>




        <a href="https://www.mybiolinks.page/studypalwebsitebuttonabovevideo">
          <button class="chromebutton">
            <img src="Chromelogo.png" alt="Chrome Logo" />
            Add to Chrome
          </button>
        </a>



        <div className="video-container">
        <video autoPlay muted loop style={{ width: '80%', height: 'auto' }} className="responsive-video">
        <source src="Studypalvideo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
  
      </div>


{/*
      <a href="/youtube-summary">
          <button class="chromebutton">
            <img src="Youtubelogo.png" alt="Youtube Logo" />
            YouTube Summary
          </button>
        </a>
  */}

        <div className="terms-spacer"></div>
      </div>

      <Footer />
    </div>
  );
}



export default App;
