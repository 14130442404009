import React from 'react';

import Header from './Header';
import Footer from './Footer';
import './App.css';


function Privacy() {
  return (

    <div className="page-container">
      <div className="content-wrap">


    <div>



<Header />
<div style={{ margin: '0 auto', maxWidth: '1000px', paddingLeft: '20px', paddingRight: '20px' }}>
      <h1 style={{ textAlign: 'left' }}>Privacy Policy</h1>
      <p style={{ textAlign: 'left' }}>
      Your privacy is important to us. This privacy policy explains how we use and protect any personal 
      information you provide to us when you use our website https://www.studypalai.com.
      </p>

      <h2 style={{ textAlign: 'left' }}>Information we collect</h2>
      <p style={{ textAlign: 'left' }}>
      We may collect the following information:
        - Name and contact information (email address, phone number, etc.)
        - Demographic information (age, location, interests, etc.)
        - Other information relevant to customer surveys and/or offers
      </p>

      <h2 style={{ textAlign: 'left' }}>How we use this information</h2>
      <p style={{ textAlign: 'left' }}>
      We use this information to understand your needs and provide you with better products and services. Specifically, we may use your information for:
        - Internal record keeping.
        - Improving our products and services.
        - Sending promotional emails or other communications about new products, special offers or other information we think you may find interesting.
        - Contacting you for market research purposes.
      </p>

      <h2 style={{ textAlign: 'left' }}>Security</h2>
      <p style={{ textAlign: 'left' }}>
      We are committed to ensuring that your information is secure.

      </p>

      <h2 style={{ textAlign: 'left' }}>Cookies</h2>
      <p style={{ textAlign: 'left' }}>
      We may use cookies on our website. A cookie is a small file that asks permission to be placed on 
      your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web 
      traffic or lets you know when you visit a particular site. Cookies allow web applications to respond 
      to you as an individual. The web application can tailor its operations to your needs, likes, and dislikes 
      by gathering and remembering information about your preferences.

      </p>

      <h2 style={{ textAlign: 'left' }}>Links to other websites</h2>

      <p style={{ textAlign: 'left' }}>
       Our website may contain links to other websites of interest. However, once you have used these links
       to leave our site, you should note that we do not have any control over that other website. Therefore, 
       we cannot be responsible for the protection and privacy of any information which you provide whilst visiting 
       such sites and such sites are not governed by this privacy statement. You should exercise caution and look at 
       the privacy statement applicable to the website in question.

      </p>

      <h2 style={{ textAlign: 'left' }}>Controlling your personal information</h2>
      <p style={{ textAlign: 'left' }}>
      You may choose to restrict the collection or use of your personal information in the following ways:
        - Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that
          you do not want the information to be used by anybody for direct marketing purposes
        - If you have previously agreed to us using your personal information for direct marketing purposes, you may
          change your mind at any time by emailing us.
      </p>

      
      <p style={{ textAlign: 'left' }}>
      We will not sell, distribute or lease your personal information to third parties unless we have your 
      permission or are required by law to do so. We may use your personal information to send you promotional 
      information about third parties which we think you may find interesting.

        </p>

      <h2 style={{ textAlign: 'left' }}>Changes to this policy</h2>
      <p style={{ textAlign: 'left' }}>
      We may change this policy from time to time by updating this page. You should check this page from time
      to time to ensure that you are happy with any changes.

      </p>

      <h2 style={{ textAlign: 'left' }}>Contact us</h2>
      <p style={{ textAlign: 'left' }}>
      If you have any questions about this privacy policy, please contact us at studypalai@gmail.com.

      </p>

      
      <div className="terms-spacer"></div>


      </div>

      <Footer />

      </div>








      </div>
    </div>
  );
}

export default Privacy;
