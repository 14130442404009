import React from 'react';

function Footer() {
  const footerStyle = {
    backgroundColor: '#37C6AC',
    padding: '30px',
    textAlign: 'center',
    fontSize: '16px',
    position: 'relative',
    bottom: '0',
    
  };

  const linkStyle = {
    color: '#333',
    textDecoration: 'none',
    marginLeft: '10px',
    marginRight: '10px',
  };

  const copyrightStyle = {
    marginTop: '20px',
  }

  return (
    <div style={footerStyle}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <a href="/terms" style={linkStyle}>
          Terms of Use
        </a>

        <span style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px' }}>
          |
        </span>

        <a href="/privacy" style={linkStyle}>
          Privacy Policy
        </a>
      </div>

      <div style={copyrightStyle}>
        © 2024 StudyPal AI. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
