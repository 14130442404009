import React from 'react';

import Header from './Header';
import Footer from './Footer';
import './App.css';


function Terms() {
  return (

    <div className="page-container">
      <div className="content-wrap">


    <div>


<Header />
<div style={{ margin: '0 auto', maxWidth: '1000px', paddingLeft: '20px', paddingRight: '20px' }}>
      <h1 style={{ textAlign: 'left' }}>Terms of Use</h1>
      <p style={{ textAlign: 'left' }}>
        Welcome to our website. If you continue to browse and use this website, https://www.studypalai.com, 
        you are agreeing to comply with and be bound by the following terms and
        conditions of use, which together with our privacy policy govern our
        relationship with you in relation to this website. If you disagree with
        any part of these terms and conditions, please do not use our website.
      </p>

      <h2 style={{ textAlign: 'left' }}>1. Introduction</h2>
      <p style={{ textAlign: 'left' }}>
        The term 'StudyPal AI', 'us' or 'we' refers to the owner of the website who can be reached at https://www.studypalai.com. 
        The term 'you' refers to the user or viewer of our website.
      </p>

      <h2 style={{ textAlign: 'left' }}>2. Use of Website</h2>
      <p style={{ textAlign: 'left' }}>
        The content of the pages of this website is for your general information and use
        only. It is subject to change without notice.
      </p>

      <h2 style={{ textAlign: 'left' }}>3. Intellectual Property</h2>
      <p style={{ textAlign: 'left' }}>
        This website contains material which is owned by or licensed to us. This material
        includes, but is not limited to, the design, layout, look, appearance and graphics.
        Reproduction is prohibited other than in accordance with the copyright notice, which
        forms part of these terms and conditions.
      </p>

      <h2 style={{ textAlign: 'left' }}>4. Links to Other Websites</h2>
      <p style={{ textAlign: 'left' }}>
        Our website may contain links to other websites of interest. However, once you have
        used these links to leave our site, you should note that we do not have any control
        over that other website. Therefore, we cannot be responsible for the protection and
        privacy of any information which you provide whilst visiting such sites and such sites
        are not governed by this privacy statement. You should exercise caution and look at
        the privacy statement applicable to the website in question.
      </p>

      <h2 style={{ textAlign: 'left' }}>5. Disclaimer</h2>

      <p style={{ textAlign: 'left' }}>
        The information contained in this website is for general information purposes only.
        The information is provided by us and while we endeavour to keep the information up to
        date and correct, we make no representations or warranties of any kind, express or
        implied, about the completeness, accuracy, reliability, suitability or availability
        with respect to the website or the information, products, services, or related graphics
        contained on the website for any purpose. Any reliance you place on such information is
        therefore strictly at your own risk. Our website, https://www.studypalai.com, should 
        not be used for any form of academic dishonesty. StudyPal AI is not associated or endorsed
        by any college or university.
      </p>

      <h2 style={{ textAlign: 'left' }}>6. Governing Law</h2>
      <p style={{ textAlign: 'left' }}>
        Your use of this website and any dispute arising out of such use of the website is subject
        to the laws of the United States.
      </p>

      <h2 style={{ textAlign: 'left' }}>7. Changes to These Terms and Conditions</h2>
      <p style={{ textAlign: 'left' }}>
        We reserve the right to modify these terms and conditions at any time without notice.
        The updated version of these terms and conditions will be effective as of the date of
        posting on the website.
        </p>

      <h2 style={{ textAlign: 'left' }}>8. Contact Us</h2>
      <p style={{ textAlign: 'left' }}>
        If you have any questions about these terms of use, please contact us at
        studypalai@gmail.com.
      </p>

      
      <div className="terms-spacer"></div>


      </div>

      <Footer />

      </div>








      </div>
    </div>
  );
}

export default Terms;
