import React, { useState } from 'react';
import './App.css';

import Header from './Header';
import Footer from './Footer';

import './Video.css'; // Ensure you have this CSS file imported

function Youtubesummary() {
 
         

  return (
    <div className="page-container">
      <div className="content-wrap">
        <Header />

        <h1></h1>
   
        

 {/* Integrated iframe */}
 <iframe
          src="https://youtubesummarizernotes.streamlit.app?embed=true"
          style={{ height: '750px', width: '80%' }}
 ></iframe>
        


        <a href="https://www.mybiolinks.page/studypalwebsitebuttonabovevideo">
          <button class="chromebutton">
            <img src="Chromelogo.png" alt="Chrome Logo" />
            Add to Chrome
          </button>
        </a>



        <div className="video-container">
        <video autoPlay muted loop style={{ width: '80%', height: 'auto' }} className="responsive-video">
        <source src="Studypalvideo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
  
      </div>


 



        <div className="terms-spacer"></div>
      </div>

      <Footer />
    </div>
  );
}



export default Youtubesummary;
